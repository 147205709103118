import React, { Suspense } from 'react';
import { BrowserRouter, Route, Router, withRouter } from 'react-router-dom';
import './scss/style.scss';
import { History } from './helper/history';
import {FullScreenSpinner} from './views/components/loading';
import routes from './routes';
import {CFade} from '@coreui/react';
import { getLocalStorageWithExpiry } from './helper/storage';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import Carousel from './views/components/carousel';


export default () => {
  const validPath = ['/register', '/login', '/forget-password', '/verify'];
  if (
    !getLocalStorageWithExpiry('token') &&
    validPath.indexOf(window.location.pathname) === -1
  ) {
    window.location.replace('/login');
    return null;
  } else if (
    getLocalStorageWithExpiry('token') &&
    validPath.indexOf(window.location.pathname) !== -1
  ) {
    // todo: if we want to access to register when the user in login this condition have to change
    window.location.replace('/');
    return null;
  } else {
    return (
      <BrowserRouter>
        <Router history={History}>
          <Suspense fallback={<FullScreenSpinner/>}>
            <Carousel/>
            <ToastContainer
              position="bottom-right"
              autoClose={parseInt(process.env.REACT_APP_TOASTER_CLOSE_TIMEOUT, 10)}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div>
              {routes.map((RouteItems, key) => {
                return (
                  <Route
                    key={key}
                    exact={RouteItems.exact}
                    path={RouteItems.path}
                    render={withRouter(props =>
                      <RouteItems.layout>
                        <CFade>
                          <RouteItems.component {...props}/>
                        </CFade>
                      </RouteItems.layout>)
                    }
                  />
                );
              })}
            </div>
          </Suspense>
        </Router>
      </BrowserRouter>
    );
  }
};
