import ApiCaller from '../helper/apiCaller';


export const countriesService = () => {
  return ApiCaller({
    method:'get',
    url: `${process.env.REACT_APP_API_URL}/api/v3/admin/definitions/countries`
  });
};
export const regionsService = () => {
  return ApiCaller({
    method:'get',
    url: `${process.env.REACT_APP_API_URL}/api/v3/admin/definitions/regions`
  });
};
export const languagesService = () => {
  return ApiCaller({
    method:'get',
    url: `${process.env.REACT_APP_API_URL}/api/v3/admin/definitions/languages`
  });
};
export const categoriesService = () => {
  return ApiCaller({
    method:'get',
    url: `${process.env.REACT_APP_API_URL}/api/v3/admin/definitions/categories`
  });
};
export const packagesService = () => {
  return ApiCaller({
    method:'get',
    url: `${process.env.REACT_APP_API_URL}/api/v3/admin/definitions/packages`
  });
};
