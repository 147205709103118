import * as actionTypes from '../../constants/actionTypes';


const initialState = {
  notifications: {
    loading: false,
    data: [],
    error: ''
  },
  create: {
    loading: false,
    error: null,
    status: ''
  },
  edit: {
    loading: false,
    error: null,
    status: ''
  },
  send: {
    loading: false,
    error: null,
    status: ''
  }
};

const setNotification = (state, payLoad) => {
  if (payLoad.reset) {
    return {...state, notifications: {...state.notifications, data: payLoad.data, loading: false}};
  } else {
    //push to data
    // state.notifications.data.push(payLoad.data)
    return {
      ...state,
      notifications: {
        ...state.notifications,
        data:[...state.notifications.data, ...payLoad.data],
        loading: false
      }
    };
  }
}

const deleteNotification = (state, payLoad) => {
  return {
    ...state,
    notifications : {
      data: state.notifications.data.filter(item => item._id !== payLoad.data),
      loading: false,
    }
  }
}

const updateNotifications = (state, payLoad) => {
  return {
    ...state,
    notifications : {
      data: state.notifications.data.map(item => {
        return item._id === payLoad.data._id ? item = payLoad.data : item
      }),
      loading: false,
    }
  }
}


const Notifications = (state = initialState, {type, payLoad, error}) => {
  switch (type) {
    case actionTypes.GET_NOTIFICATIONS:
      return {...state, notifications: {...state.notifications, loading: true}};
    case actionTypes.SET_NOTIFICATIONS:
      return setNotification(state, payLoad)
    case actionTypes.NOTIFICATION_FAILURE:
      return {...state, notifications: {...state.notifications, error: error, loading: false}};
    case actionTypes.STATUS_CREATE_NOTIFICATION:
      return {...state, create: {...state.create, error: error, loading: payLoad.loading, status: payLoad.status}};
    case actionTypes.STATUS_EDIT_NOTIFICATION:
      return {...state, edit: {...state.edit, error: error, loading: payLoad.loading, status: payLoad.status}};
    case actionTypes.SEND_NOTIFICATION:
      return {...state, send: {...state.send, error: error, loading: payLoad.loading, status: payLoad.status}};
    case actionTypes.EDIT_NOTIFICATION:
      return updateNotifications(state, payLoad);
    case actionTypes.DELETE_NOTIFICATION:
      return deleteNotification(state, payLoad);

    default:
      return state;
  }
};

export default Notifications;
