import * as actionTypes from '../../constants/actionTypes';


const initialState = {
  login: {
    loading: false,
    data: [],
    error: ''
  }
};

const Auth = (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case actionTypes.LOGIN_REQUEST:
      return {...state, login: { ...state.login, loading: true }};
    case actionTypes.LOGIN_SUCCESS:
      return {...state, login: { ...state.login, data: payLoad, loading: false }};
    case actionTypes.LOGIN_FAILURE:
      return {...state, login: { ...state.login, error: error, loading: false }};

    default:
      return state;
  }
};

export default Auth;
