import * as actionTypes from '../../constants/actionTypes';

const initialState = {
	getByFilter: {
		loading: false,
		data: [],
		error: '',
	},
	create: {
		loading: false,
		data: [],
		error: '',
	},
	getInformation: {
		loading: false,
		data: [],
		error: '',
	},
	find: {
		loading: false,
		data: [],
		error: '',
	},
	updateStatus: {
		loading: false,
		data: [],
		error: '',
	},
	uploadImage: {
		loading: false,
		data: [],
		error: '',
	},
	deletePhotoDocuments: {
		loading: false,
		data: [],
		error: '',
	},
	deletePhotoCertificate: {
		loading: false,
		data: [],
		error: '',
	},
	deletePhotoCompany: {
		loading: false,
		data: [],
		error: '',
	},
};

const Companies = (state = initialState, { type, payLoad, error }) => {
	switch (type) {
		case actionTypes.GET_BY_FILTER_REQUEST:
			return { ...state, getByFilter: { ...state.getByFilter, loading: true } };
		case actionTypes.GET_BY_FILTER_SUCCESS:
			return { ...state, getByFilter: { ...state.getByFilter, data: payLoad, loading: false } };
		case actionTypes.GET_BY_FILTER_FAILURE:
			return { ...state, getByFilter: { ...state.getByFilter, error: error, loading: false } };

		case actionTypes.CREATE_REQUEST:
			return { ...state, create: { ...state.create, loading: true } };
		case actionTypes.CREATE_SUCCESS:
			return { ...state, create: { ...state.create, data: payLoad, loading: false } };
		case actionTypes.CREATE_FAILURE:
			return { ...state, create: { ...state.create, error: error, loading: false } };

		case actionTypes.GET_COMPANY_INFORMATION_REQUEST:
			return { ...state, getInformation: { ...state.getInformation, loading: true } };
		case actionTypes.GET_COMPANY_INFORMATION_SUCCESS:
			return { ...state, getInformation: { ...state.getInformation, data: payLoad, loading: false } };
		case actionTypes.GET_COMPANY_INFORMATION_FAILURE:
			return { ...state, getInformation: { ...state.getInformation, error: error, loading: false } };

		case actionTypes.FIND_COMPANY_REQUEST:
			return { ...state, find: { ...state.find, loading: true } };
		case actionTypes.FIND_COMPANY_SUCCESS:
			return { ...state, find: { ...state.find, data: payLoad, loading: false } };
		case actionTypes.FIND_COMPANY_FAILURE:
			return { ...state, find: { ...state.find, error: error, loading: false } };

		case actionTypes.COMPANY_UPDATE_STATUS_REQUEST:
			return { ...state, updateStatus: { ...state.updateStatus, loading: true } };
		case actionTypes.COMPANY_UPDATE_STATUS_SUCCESS:
			return { ...state, updateStatus: { ...state.updateStatus, data: payLoad, loading: false } };
		case actionTypes.COMPANY_UPDATE_STATUS_FAILURE:
			return { ...state, updateStatus: { ...state.updateStatus, error: error, loading: false } };

		case actionTypes.COMPANY_UPLOAD_IMAGE_REQUEST:
			return { ...state, uploadImage: { ...state.uploadImage, loading: true } };
		case actionTypes.COMPANY_UPLOAD_IMAGE_SUCCESS:
			return { ...state, uploadImage: { ...state.uploadImage, data: payLoad, loading: false } };
		case actionTypes.COMPANY_UPLOAD_IMAGE_FAILURE:
			return { ...state, uploadImage: { ...state.uploadImage, error: error, loading: false } };

		case actionTypes.DELETE_PHOTO_DOCUMENTS_REQUEST:
			return { ...state, deletePhotoDocuments: { ...state.deletePhotoDocuments, loading: true } };
		case actionTypes.DELETE_PHOTO_DOCUMENTS_SUCCESS:
			return { ...state, deletePhotoDocuments: { ...state.deletePhotoDocuments, data: payLoad, loading: false } };
		case actionTypes.DELETE_PHOTO_DOCUMENTS_FAILURE:
			return { ...state, deletePhotoDocuments: { ...state.deletePhotoDocuments, error: error, loading: false } };

		case actionTypes.DELETE_PHOTO_CERTIFICATE_REQUEST:
			return { ...state, deletePhotoCertificate: { ...state.deletePhotoCertificate, loading: true } };
		case actionTypes.DELETE_PHOTO_CERTIFICATE_SUCCESS:
			return { ...state, deletePhotoCertificate: { ...state.deletePhotoCertificate, data: payLoad, loading: false } };
		case actionTypes.DELETE_PHOTO_CERTIFICATE_FAILURE:
			return { ...state, deletePhotoCertificate: { ...state.deletePhotoCertificate, error: error, loading: false } };

		case actionTypes.DELETE_PHOTO_COMPANY_REQUEST:
			return { ...state, deletePhotoCompany: { ...state.deletePhotoCompany, loading: true } };
		case actionTypes.DELETE_PHOTO_COMPANY_SUCCESS:
			return { ...state, deletePhotoCompany: { ...state.deletePhotoCompany, data: payLoad, loading: false } };
		case actionTypes.DELETE_PHOTO_COMPANY_FAILURE:
			return { ...state, deletePhotoCompany: { ...state.deletePhotoCompany, error: error, loading: false } };

		default:
			return state;
	}
};

export default Companies;
