import React from 'react';
import {withRouter} from 'react-router-dom';


export default withRouter(({children}) => {
  return (
    <div style={{backgroundColor: '#ccc', width: '100%', height: '100vh'}}>
      {children}
    </div>
  );
});
