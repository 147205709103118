import * as actionTypes from './../../constants/actionTypes';
import {
  categoriesService,
  countriesService,
  languagesService,
  regionsService,
  packagesService
} from '../../services/definitionsService';


export const countries = () => {
  return async (dispatch) => {
    dispatch({type: actionTypes.COUNTRIES_REQUEST});
    try {
      const response =  await countriesService();
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.COUNTRIES_SUCCESS,
          payLoad: data.result
        });
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.COUNTRIES_FAILURE,
          error: { messages: data.messages }
        });
      }
    } catch(error) {
      if (error.data) {
        dispatch({
          type: actionTypes.COUNTRIES_FAILURE,
          error: { message: error.data.messages }
        });
      }
    }
  };
};
export const regions = () => {
  return async (dispatch) => {
    dispatch({type: actionTypes.REGIONS_REQUEST});
    try {
      const response = await regionsService();
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.REGIONS_SUCCESS,
          payLoad: data.result
        });
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.REGIONS_FAILURE,
          error: { messages: data.messages }
        });
      }
    } catch(error) {
      if (error.data) {
        dispatch({
          type: actionTypes.REGIONS_FAILURE,
          error: { message: error.data.messages }
        });
      }
    }
  };
};
export const languages = () => {
  return async (dispatch) => {
    dispatch({type: actionTypes.LANGUAGES_REQUEST});
    try {
      const response = await languagesService();
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.LANGUAGES_SUCCESS,
          payLoad: data.result
        });
      }
      if (data.status !== 200) {
        dispatch({
          type: actionTypes.LANGUAGES_FAILURE,
          error: { messages: data.messages }
        });
      }
    } catch(error) {
      if (error.data) {
        dispatch({
          type: actionTypes.LANGUAGES_FAILURE,
          error: { message: error.data.messages }
        });
      }
    }
  };
};
export const categories = () => {
  return async (dispatch) => {
    dispatch({type: actionTypes.CATEGORIES_REQUEST});
    try {
      const response = await categoriesService();
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.CATEGORIES_SUCCESS,
          payLoad: data.result
        });
      }
      if (data.status !== 200) {
        dispatch({
          type: actionTypes.CATEGORIES_FAILURE,
          error: { messages: data.messages }
        });
      }
    } catch(error) {
      if (error.data) {
        dispatch({
          type: actionTypes.CATEGORIES_FAILURE,
          error: { message: error.data.messages }
        });
      }
    }
  };
};
export const packages = () => {
  return async (dispatch) => {
    dispatch({type: actionTypes.PACKAGES_REQUEST});
    try {
      const response = await packagesService();
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.PACKAGES_SUCCESS,
          payLoad: data.result
        });
      }
      if (data.status !== 200) {
        dispatch({
          type: actionTypes.PACKAGES_FAILURE,
          error: { messages: data.messages }
        });
      }
    } catch(error) {
      if (error.data) {
        dispatch({
          type: actionTypes.PACKAGES_FAILURE,
          error: { message: error.data.messages }
        });
      }
    }
  };
};
