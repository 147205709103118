import ApiCaller from '../helper/apiCaller';


export const loginService = (postData) => {
  return ApiCaller({
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/api/v3/admin/auth/login`,
    data: postData
  });
};
