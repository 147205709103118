import * as actionTypes from '../../constants/actionTypes';

const initialState = {
	getByFilter: {
		loading: false,
		data: [],
		error: '',
	},
	getById: {
		loading: false,
		data: [],
		error: '',
	},
	uploadImage: {
		loading: false,
		data: [],
		error: '',
	},
	isUserTest: {
		loading: false,
		data: [],
		error: '',
	},
	deleteUsers: {
		loading: false,
		data: [],
		error: '',
	},
	upgradeMembershipPlan: {
		loading: false,
		data: [],
		error: '',
	},
	downgradeMembershipPlan: {
		loading: false,
		data: [],
		error: '',
	},
	getUserUpgradeDowngradeList: {
		loading: false,
		data: [],
		error: '',
	},
	changeInterestedCategory: {
		loading: false,
		data: [],
		error: '',
	},
	changeRequestStatus: {
		loading: false,
		data: [],
		error: '',
	},
};

const Users = (state = initialState, { type, payLoad, error }) => {
	switch (type) {
		case actionTypes.GET_USER_BY_FILTER_REQUEST:
			return { ...state, getByFilter: { ...state.getByFilter, loading: true } };
		case actionTypes.GET_USER_BY_FILTER_SUCCESS:
			return { ...state, getByFilter: { ...state.getByFilter, data: payLoad, loading: false } };
		case actionTypes.GET_USER_BY_FILTER_FAILURE:
			return { ...state, getByFilter: { ...state.getByFilter, error: error, loading: false } };

		case actionTypes.GET_USER_BY_ID_REQUEST:
			return { ...state, getById: { ...state.getById, loading: true } };
		case actionTypes.GET_USER_BY_ID_SUCCESS:
			return { ...state, getById: { ...state.getById, data: payLoad, loading: false } };
		case actionTypes.GET_USER_BY_ID_FAILURE:
			return { ...state, getById: { ...state.getById, error: error, loading: false } };

		case actionTypes.USER_UPLOAD_IMAGE_REQUEST:
			return { ...state, uploadImage: { ...state.uploadImage, loading: true } };
		case actionTypes.USER_UPLOAD_IMAGE_SUCCESS:
			return { ...state, uploadImage: { ...state.uploadImage, data: payLoad, loading: false } };
		case actionTypes.USER_UPLOAD_IMAGE_FAILURE:
			return { ...state, uploadImage: { ...state.uploadImage, error: error, loading: false } };

		case actionTypes.IS_USER_TEST_REQUEST:
			return { ...state, isUserTest: { ...state.isUserTest, loading: true } };
		case actionTypes.IS_USER_TEST_SUCCESS:
			return { ...state, isUserTest: { ...state.isUserTest, data: payLoad, loading: false } };
		case actionTypes.IS_USER_TEST_FAILURE:
			return { ...state, isUserTest: { ...state.isUserTest, error: error, loading: false } };

		case actionTypes.DELETE_TEST_USER_REQUEST:
			return { ...state, deleteUsers: { ...state.deleteUsers, loading: true } };
		case actionTypes.DELETE_TEST_USER_SUCCESS:
			return { ...state, deleteUsers: { ...state.deleteUsers, data: payLoad, loading: false } };
		case actionTypes.DELETE_TEST_USER_FAILURE:
			return { ...state, deleteUsers: { data: [], error: error, loading: false } };

		case actionTypes.UPGRADE_MEMBERSHIP_PLAN_REQUEST:
			return { ...state, upgradeMembershipPlan: { ...state.upgradeMembershipPlan, loading: true } };
		case actionTypes.UPGRADE_MEMBERSHIP_PLAN_SUCCESS:
			return { ...state, upgradeMembershipPlan: { ...state.upgradeMembershipPlan, data: payLoad, loading: false } };
		case actionTypes.UPGRADE_MEMBERSHIP_PLAN_FAILURE:
			return { ...state, upgradeMembershipPlan: { data: [], error: error, loading: false } };

		case actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_REQUEST:
			return { ...state, downgradeMembershipPlan: { ...state.downgradeMembershipPlan, loading: true } };
		case actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_SUCCESS:
			return { ...state, downgradeMembershipPlan: { ...state.downgradeMembershipPlan, data: payLoad, loading: false } };
		case actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_FAILURE:
			return { ...state, downgradeMembershipPlan: { data: [], error: error, loading: false } };

		case actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_REQUEST:
			return { ...state, getUserUpgradeDowngradeList: { ...state.getUserUpgradeDowngradeList, loading: true } };
		case actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_SUCCESS:
			return {
				...state,
				getUserUpgradeDowngradeList: { ...state.getUserUpgradeDowngradeList, data: payLoad, loading: false },
			};
		case actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_FAILURE:
			return { ...state, getUserUpgradeDowngradeList: { data: [], error: error, loading: false } };

		case actionTypes.CHANGE_INTERESTED_CATEGORY_REQUEST:
			return { ...state, changeInterestedCategory: { ...state.changeInterestedCategory, loading: true } };
		case actionTypes.CHANGE_INTERESTED_CATEGORY_SUCCESS:
			return {
				...state,
				changeInterestedCategory: { ...state.changeInterestedCategory, data: payLoad, loading: false },
			};
		case actionTypes.CHANGE_INTERESTED_CATEGORY_FAILURE:
			return { ...state, changeInterestedCategory: { data: [], error: error, loading: false } };

		case actionTypes.CHANGE_REQUEST_STATUS_REQUEST:
			return { ...state, changeRequestStatus: { ...state.changeRequestStatus, loading: true } };
		case actionTypes.CHANGE_REQUEST_STATUS_SUCCESS:
			return {
				...state,
				changeRequestStatus: { ...state.changeRequestStatus, data: payLoad, loading: false },
			};
		case actionTypes.CHANGE_REQUEST_STATUS_FAILURE:
			return { ...state, changeRequestStatus: { data: [], error: error, loading: false } };

		default:
			return state;
	}
};

export default Users;
