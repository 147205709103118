import * as actionTypes from './../../constants/actionTypes';
import {
  deleteUsersService,
  downgradeMembershipPlanService,
  getByFilterService,
  getByIdService,
  getUserPWService,
  getUserUpgradeDownGradeListService,
  isUserTestService,
  upgradeMembershipPlanService,
  uploadImageService,
  changeInterestedCategoryService,
  changeRequestStatusService,
} from '../../services/userService';
import { getLocalStorageWithExpiry } from '../../helper/storage';
import { toast } from 'react-toastify';

export const getByFilter = filter => {
  return async dispatch => {
    dispatch({ type: actionTypes.GET_USER_BY_FILTER_REQUEST });
    try {
      const token = getLocalStorageWithExpiry('token');
      const response = await getByFilterService({ ...filter, token });
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.GET_USER_BY_FILTER_SUCCESS,
          payLoad: data.result,
        });
      }
      if (response.status === 401) {
        dispatch({
          type: actionTypes.GET_USER_BY_FILTER_FAILURE,
          error: { messages: data.messages },
        });
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.GET_USER_BY_FILTER_FAILURE,
          error: { message: error.data.messages },
        });
      }
    }
  };
};

export const getById = id => {
  return async dispatch => {
    dispatch({ type: actionTypes.GET_USER_BY_ID_REQUEST });
    try {
      const response = await getByIdService(id);
      const data = response.data;
      if (data.status === 200) {
        const responseUserPw = await getUserPWService(id);
        dispatch({
          type: actionTypes.GET_USER_BY_ID_SUCCESS,
          payLoad: { ...data.result, ...responseUserPw.data.result },
        });
      }
      if (response.status === 401) {
        dispatch({
          type: actionTypes.GET_USER_BY_ID_FAILURE,
          error: { messages: data.messages },
        });
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.GET_USER_BY_ID_FAILURE,
          error: { message: error.data.messages },
        });
      }
    }
  };
};

export const uploadImage = (postData, userId) => {
  return async dispatch => {
    dispatch({ type: actionTypes.USER_UPLOAD_IMAGE_REQUEST });
    try {
      const response = await uploadImageService(postData);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.USER_UPLOAD_IMAGE_SUCCESS,
          payLoad: data.result,
        });
        await dispatch(getById(userId));
        toast.success('User image updated successfully.');
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.USER_UPLOAD_IMAGE_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.USER_UPLOAD_IMAGE_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('User image update failed!');
      }
    }
  };
};

export const isUserTest = payLoad => {
  return async dispatch => {
    dispatch({ type: actionTypes.IS_USER_TEST_REQUEST });
    try {
      const response = await isUserTestService({ ...payLoad.postData, token: payLoad.userId }, payLoad.userId);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.IS_USER_TEST_SUCCESS,
          payLoad: data.result,
        });
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.IS_USER_TEST_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.IS_USER_TEST_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};

export const deleteUsers = (postData, currentPage) => {
  return async dispatch => {
    dispatch({ type: actionTypes.DELETE_TEST_USER_REQUEST });
    try {
      const response = await deleteUsersService(postData);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.DELETE_TEST_USER_SUCCESS,
          payLoad: data.result,
        });
        toast.success(data.result.message);
        await dispatch(getByFilter({ page: currentPage, take: 10 }));
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.DELETE_TEST_USER_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(response.data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.DELETE_TEST_USER_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};

export const upgradeMembershipPlan = postData => {
  return async dispatch => {
    dispatch({ type: actionTypes.UPGRADE_MEMBERSHIP_PLAN_REQUEST });
    try {
      const response = await upgradeMembershipPlanService(postData);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.UPGRADE_MEMBERSHIP_PLAN_SUCCESS,
          payLoad: data.result,
        });
        toast.success(data.result.message);
        await dispatch(getById(postData.user_id));
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.UPGRADE_MEMBERSHIP_PLAN_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(response.data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.UPGRADE_MEMBERSHIP_PLAN_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};

export const downgradeMembershipPlan = postData => {
  return async dispatch => {
    dispatch({ type: actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_REQUEST });
    try {
      const response = await downgradeMembershipPlanService(postData);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_SUCCESS,
          payLoad: data.result,
        });
        toast.success(data.result.message);
        await dispatch(getById(postData.user_id));
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(response.data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.DOWNGRADE_MEMBERSHIP_PLAN_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};

export const getUserUpgradeDownGradeList = postData => {
  const token = getLocalStorageWithExpiry('token');
  return async dispatch => {
    dispatch({ type: actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_REQUEST });
    try {
      const response = await getUserUpgradeDownGradeListService({ ...postData, token });
      const data = response.data;
      if (data && data.status && data.status === 200) {
        dispatch({
          type: actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_SUCCESS,
          payLoad: data.result,
        });
        toast.success(data.result.message);
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(response.data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.GET_USER_UPGRADE_DOWNGRADE_LIST_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};

export const changeInterestedCategory = postData => {
  const token = getLocalStorageWithExpiry('token');
  return async dispatch => {
    dispatch({ type: actionTypes.CHANGE_INTERESTED_CATEGORY_REQUEST });
    try {
      const response = await changeInterestedCategoryService({ ...postData, token });
      const data = response.data;
      if (data && data.status && data.status === 200) {
        dispatch({
          type: actionTypes.CHANGE_INTERESTED_CATEGORY_SUCCESS,
          payLoad: data.result,
        });
        toast.success(data.result.message);
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.CHANGE_INTERESTED_CATEGORY_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(response.data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.CHANGE_INTERESTED_CATEGORY_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};

export const changeRequestStatus = payLoad => {
  const token = getLocalStorageWithExpiry('token');
  return async dispatch => {
    dispatch({ type: actionTypes.CHANGE_REQUEST_STATUS_REQUEST });
    try {
      const response = await changeRequestStatusService({ ...payLoad.data, token });
      const data = response.data;
      if (data && data.status && data.status === 200) {
        dispatch({
          type: actionTypes.CHANGE_REQUEST_STATUS_SUCCESS,
          payLoad: data.result,
        });
        toast.success(data.result.message);
        await dispatch(getUserUpgradeDownGradeList(payLoad.filter));
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.CHANGE_REQUEST_STATUS_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning(response.data.messages.toString());
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.CHANGE_REQUEST_STATUS_FAILURE,
          error: { message: error.data.messages },
        });
        toast.error('Something went wrong!');
      }
    }
  };
};
