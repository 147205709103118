import * as actionTypes from '../../constants/actionTypes';

const initialState = {
	getByFilter: {
		loading: false,
		data: [],
		error: '',
	},
	getById: {
		loading: false,
		data: [],
		error: '',
	},
	updateStatus: {
		loading: false,
		data: [],
		error: '',
	},
	deletePhotoPost: {
		loading: false,
		data: [],
		error: '',
	},
};

const Posts = (state = initialState, { type, payLoad, error }) => {
	switch (type) {
		case actionTypes.GET_POST_BY_FILTER_REQUEST:
			return { ...state, getByFilter: { ...state.getByFilter, loading: true } };
		case actionTypes.GET_POST_BY_FILTER_SUCCESS:
			return { ...state, getByFilter: { ...state.getByFilter, data: payLoad, loading: false } };
		case actionTypes.GET_POST_BY_FILTER_FAILURE:
			return { ...state, getByFilter: { ...state.getByFilter, error: error, loading: false } };

		case actionTypes.GET_POST_BY_ID_REQUEST:
			return { ...state, getById: { ...state.getById, loading: true } };
		case actionTypes.GET_POST_BY_ID_SUCCESS:
			return { ...state, getById: { ...state.getById, data: payLoad, loading: false } };
		case actionTypes.GET_POST_BY_ID_FAILURE:
			return { ...state, getById: { ...state.getById, error: error, loading: false } };

		case actionTypes.POST_UPDATE_STATUS_REQUEST:
			return { ...state, updateStatus: { ...state.updateStatus, loading: true } };
		case actionTypes.POST_UPDATE_STATUS_SUCCESS:
			return { ...state, updateStatus: { ...state.updateStatus, data: payLoad, loading: false } };
		case actionTypes.POST_UPDATE_STATUS_FAILURE:
			return { ...state, updateStatus: { ...state.updateStatus, error: error, loading: false } };

		case actionTypes.DELETE_PHOTO_POST_REQUEST:
			return { ...state, deletePhotoPost: { ...state.deletePhotoPost, loading: true } };
		case actionTypes.DELETE_PHOTO_POST_SUCCESS:
			return { ...state, deletePhotoPost: { ...state.deletePhotoPost, data: payLoad, loading: false } };
		case actionTypes.DELETE_PHOTO_POST_FAILURE:
			return { ...state, deletePhotoPost: { ...state.deletePhotoPost, error: error, loading: false } };

		default:
			return state;
	}
};

export default Posts;
