import {combineReducers} from 'redux';
import Auth from './auth';
import Companies from './companies';
import Definitions from './definitions';
import Posts from './posts';
import Users from './users';
import Carousel from './carousel';
import Notifications from './notifications';

import globalReducer from './global';
import postsReducer from './posts';

export default combineReducers({
  Auth,
  Definitions,
  Companies,
  Posts,
  Users,
  Notifications,
  Carousel,

  global: globalReducer,
  posts: postsReducer
});
