import * as actionTypes from './../../constants/actionTypes';

export const carouselShow = (images, activeIndex) => {
  return (dispatch) => {
    dispatch({type: actionTypes.CAROUSEL_SHOW, payLoad: {images, activeIndex}});
  };
};

export const carouselHide = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.CAROUSEL_HIDE});
  };
};
