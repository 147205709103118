import * as actionTypes from './../../constants/actionTypes';
import {
  getByFilterService,
  getByIdService,
  updateStatusService,
  deletePhotoPostService,
} from '../../services/postService';
import { getLocalStorageWithExpiry } from '../../helper/storage';
import { toast } from 'react-toastify';

export const getByFilter = filter => {
  return async dispatch => {
    dispatch({ type: actionTypes.GET_POST_BY_FILTER_REQUEST });
    try {
      const token = getLocalStorageWithExpiry('token');
      const response = await getByFilterService({ ...filter, token });
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.GET_POST_BY_FILTER_SUCCESS,
          payLoad: data.result,
        });
      }
      if (response.status === 401) {
        dispatch({
          type: actionTypes.GET_POST_BY_FILTER_FAILURE,
          error: { messages: data.messages },
        });
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.GET_POST_BY_FILTER_FAILURE,
          error: { message: error.data.messages },
        });
      }
    }
  };
};

export const getById = id => {
  return async dispatch => {
    dispatch({ type: actionTypes.GET_POST_BY_ID_REQUEST });
    try {
      const response = await getByIdService(id);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.GET_POST_BY_ID_SUCCESS,
          payLoad: data.result,
        });
      }
      if (response.status === 401) {
        dispatch({
          type: actionTypes.GET_POST_BY_ID_FAILURE,
          error: { messages: data.messages },
        });
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.GET_POST_BY_ID_FAILURE,
          error: { message: error.data.messages },
        });
      }
    }
  };
};

export const updateStatus = postData => {
  console.log("data")

  const token = getLocalStorageWithExpiry('token');
  return async dispatch => {
    dispatch({ type: actionTypes.POST_UPDATE_STATUS_REQUEST });
    try {
      const response = await updateStatusService({ ...postData, token });
      const data = response.data;
      console.log(data)
      if (data.status === 200) {
        dispatch({
          type: actionTypes.POST_UPDATE_STATUS_SUCCESS,
          payLoad: data.result,
        });
        await dispatch(getById(postData.post_id));
        toast.success('Post status updated successfully.');
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.POST_UPDATE_STATUS_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning('Post status update failed!');
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.POST_UPDATE_STATUS_FAILURE,
          error: { message: error.data.messages },
        });
        toast.warning('Post status update failed!');
      }
    }
  };
};

export const deletePhotoPost = (postId, photoId) => {
  return async dispatch => {
    dispatch({ type: actionTypes.DELETE_PHOTO_POST_REQUEST });
    try {
      const response = await deletePhotoPostService(postId, photoId);
      const data = response.data;
      if (data.status === 200) {
        dispatch({
          type: actionTypes.DELETE_PHOTO_POST_SUCCESS,
          payLoad: data.result,
        });
        await dispatch(getById(postId));
        toast.success('Post image deleted successfully.');
      }
      if (response.status !== 200) {
        dispatch({
          type: actionTypes.DELETE_PHOTO_POST_FAILURE,
          error: { messages: data.messages },
        });
        toast.warning('Post image deletion failed!');
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.DELETE_PHOTO_POST_FAILURE,
          error: { message: error.data.messages },
        });
        toast.warning('Post image deletion failed!');
      }
    }
  };
};
