import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  loading: false,
  show: false,
  images: [],
  activeIndex: 0
};

const Carousel = (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.CAROUSEL_SHOW:
      return {...state, show: true, images: payLoad.images, activeIndex: payLoad.activeIndex};
    case actionTypes.CAROUSEL_HIDE:
      return {...state, show: false, images: [], activeIndex: 0};

    default:
      return state;
  }
};

export default Carousel;
