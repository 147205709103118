import React, { useEffect } from 'react';
import {
  CCarousel,
  CCarouselIndicators,
  CCarouselInner,
  CCarouselItem,
  CCarouselCaption,
  CCarouselControl
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { CarouselFullContainer, Close, Img } from './style';
import { connect } from 'react-redux';
import { CarouselAction } from '../../../store/actions';
import { useLocation } from 'react-router-dom';


const Carousel = ({show, images, close, activeIndex}) => {
  const location = useLocation();
  useEffect(() => {
    return () => close();
  }, []);
  useEffect(() => {
    if (show) {
      close();
    }
  }, [location]);
  return (
    <CarouselFullContainer show={show}>
      <Close onClick={() => close()}><CIcon name='cil-x' size='2xl' className='text-white'/></Close>
      {show &&
        <CCarousel activeIndex={activeIndex} animate>
          {images.length > 1 && <CCarouselIndicators/>}
          <CCarouselInner>
            {images && images.length !== 0 && images.map((image, key) => {
              return (
                <CCarouselItem key={key}>
                  <Img src={image.src} alt={image.title}/>
                  {image.title && image.caption &&
                  <CCarouselCaption>
                    {image.title && <h3>{image.title}</h3>}
                    {image.caption && <p>{image.caption}</p>}
                  </CCarouselCaption>
                  }
                </CCarouselItem>
              );
            })}
          </CCarouselInner>
          {images.length > 1 &&
          <>
            <CCarouselControl direction="prev"/>
            <CCarouselControl direction="next"/>
          </>
          }
        </CCarousel>
      }
    </CarouselFullContainer>
  );
};

const mapStateToProps = (store) => {
  return {
    show: store.Carousel.show,
    images: store.Carousel.images,
    activeIndex: store.Carousel.activeIndex,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(CarouselAction.carouselHide())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
