// START auth types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
// END auth types

// START definitions types
export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_FAILURE = 'COUNTRIES_FAILURE';
export const REGIONS_REQUEST = 'REGIONS_REQUEST';
export const REGIONS_SUCCESS = 'REGIONS_SUCCESS';
export const REGIONS_FAILURE = 'REGIONS_FAILURE';
export const LANGUAGES_REQUEST = 'LANGUAGES_REQUEST';
export const LANGUAGES_FAILURE = 'LANGUAGES_FAILURE';
export const LANGUAGES_SUCCESS = 'LANGUAGES_SUCCESS';
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';
export const PACKAGES_REQUEST = 'PACKAGES_REQUEST';
export const PACKAGES_SUCCESS = 'PACKAGES_SUCCESS';
export const PACKAGES_FAILURE = 'PACKAGES_FAILURE';
// END definitions types

// START companies
export const GET_BY_FILTER_REQUEST = 'GET_BY_FILTER_REQUEST';
export const GET_BY_FILTER_SUCCESS = 'GET_BY_FILTER_SUCCESS';
export const GET_BY_FILTER_FAILURE = 'GET_BY_FILTER_FAILURE';
export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILURE = 'CREATE_FAILURE';
export const GET_COMPANY_INFORMATION_REQUEST = 'GET_COMPANY_INFORMATION_REQUEST';
export const GET_COMPANY_INFORMATION_SUCCESS = 'GET_COMPANY_INFORMATION_SUCCESS';
export const GET_COMPANY_INFORMATION_FAILURE = 'GET_COMPANY_INFORMATION_FAILURE';
export const FIND_COMPANY_REQUEST = 'FIND_COMPANY_REQUEST';
export const FIND_COMPANY_SUCCESS = 'FIND_COMPANY_SUCCESS';
export const FIND_COMPANY_FAILURE = 'FIND_COMPANY_FAILURE';
export const COMPANY_UPDATE_STATUS_REQUEST = 'COMPANY_UPDATE_STATUS_REQUEST';
export const COMPANY_UPDATE_STATUS_SUCCESS = 'COMPANY_UPDATE_STATUS_SUCCESS';
export const COMPANY_UPDATE_STATUS_FAILURE = 'COMPANY_UPDATE_STATUS_FAILURE';
export const COMPANY_UPLOAD_IMAGE_REQUEST = 'COMPANY_UPLOAD_IMAGE_REQUEST';
export const COMPANY_UPLOAD_IMAGE_SUCCESS = 'COMPANY_UPLOAD_IMAGE_SUCCESS';
export const COMPANY_UPLOAD_IMAGE_FAILURE = 'COMPANY_UPLOAD_IMAGE_FAILURE';
export const DELETE_PHOTO_DOCUMENTS_REQUEST = 'DELETE_PHOTO_DOCUMENTS_REQUEST';
export const DELETE_PHOTO_DOCUMENTS_SUCCESS = 'DELETE_PHOTO_DOCUMENTS_SUCCESS';
export const DELETE_PHOTO_DOCUMENTS_FAILURE = 'DELETE_PHOTO_DOCUMENTS_FAILURE';
export const DELETE_PHOTO_CERTIFICATE_REQUEST = 'DELETE_PHOTO_CERTIFICATE_REQUEST';
export const DELETE_PHOTO_CERTIFICATE_SUCCESS = 'DELETE_PHOTO_CERTIFICATE_SUCCESS';
export const DELETE_PHOTO_CERTIFICATE_FAILURE = 'DELETE_PHOTO_CERTIFICATE_FAILURE';
export const DELETE_PHOTO_COMPANY_REQUEST = 'DELETE_PHOTO_COMPANY_REQUEST';
export const DELETE_PHOTO_COMPANY_SUCCESS = 'DELETE_PHOTO_COMPANY_SUCCESS';
export const DELETE_PHOTO_COMPANY_FAILURE = 'DELETE_PHOTO_COMPANY_FAILURE';
// END companies

// START posts
export const GET_POST_BY_FILTER_REQUEST = 'GET_POST_BY_FILTER_REQUEST';
export const GET_POST_BY_FILTER_SUCCESS = 'GET_POST_BY_FILTER_SUCCESS';
export const GET_POST_BY_FILTER_FAILURE = 'GET_POST_BY_FILTER_FAILURE';
export const GET_POST_BY_ID_REQUEST = 'GET_POST_BY_ID_REQUEST';
export const GET_POST_BY_ID_SUCCESS = 'GET_POST_BY_ID_SUCCESS';
export const GET_POST_BY_ID_FAILURE = 'GET_POST_BY_ID_FAILURE';
export const POST_UPDATE_STATUS_REQUEST = 'POST_UPDATE_STATUS_REQUEST';
export const POST_UPDATE_STATUS_SUCCESS = 'POST_UPDATE_STATUS_SUCCESS';
export const POST_UPDATE_STATUS_FAILURE = 'POST_UPDATE_STATUS_FAILURE';
export const DELETE_PHOTO_POST_REQUEST = 'DELETE_PHOTO_POST_REQUEST';
export const DELETE_PHOTO_POST_SUCCESS = 'DELETE_PHOTO_POST_SUCCESS';
export const DELETE_PHOTO_POST_FAILURE = 'DELETE_PHOTO_POST_FAILURE';
export const GET_USER_UPGRADE_DOWNGRADE_LIST_REQUEST = 'GET_USER_UPGRADE_DOWNGRADE_LIST_REQUEST';
export const GET_USER_UPGRADE_DOWNGRADE_LIST_SUCCESS = 'GET_USER_UPGRADE_DOWNGRADE_LIST_SUCCESS';
export const GET_USER_UPGRADE_DOWNGRADE_LIST_FAILURE = 'GET_USER_UPGRADE_DOWNGRADE_LIST_FAILURE';
// END posts

// START users
export const GET_USER_BY_FILTER_REQUEST = 'GET_USER_BY_FILTER_REQUEST';
export const GET_USER_BY_FILTER_SUCCESS = 'GET_USER_BY_FILTER_SUCCESS';
export const GET_USER_BY_FILTER_FAILURE = 'GET_USER_BY_FILTER_FAILURE';
export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE';
export const USER_UPLOAD_IMAGE_REQUEST = 'USER_UPLOAD_IMAGE_REQUEST';
export const USER_UPLOAD_IMAGE_SUCCESS = 'USER_UPLOAD_IMAGE_SUCCESS';
export const USER_UPLOAD_IMAGE_FAILURE = 'USER_UPLOAD_IMAGE_FAILURE';
export const IS_USER_TEST_REQUEST = 'IS_USER_TEST_REQUEST';
export const IS_USER_TEST_SUCCESS = 'IS_USER_TEST_SUCCESS';
export const IS_USER_TEST_FAILURE = 'IS_USER_TEST_FAILURE';
export const DELETE_TEST_USER_REQUEST = 'DELETE_TEST_USER_REQUEST';
export const DELETE_TEST_USER_SUCCESS = 'DELETE_TEST_USER_SUCCESS';
export const DELETE_TEST_USER_FAILURE = 'DELETE_TEST_USER_FAILURE';
export const UPGRADE_MEMBERSHIP_PLAN_REQUEST = 'UPGRADE_MEMBERSHIP_PLAN_REQUEST';
export const UPGRADE_MEMBERSHIP_PLAN_SUCCESS = 'UPGRADE_MEMBERSHIP_PLAN_SUCCESS';
export const UPGRADE_MEMBERSHIP_PLAN_FAILURE = 'UPGRADE_MEMBERSHIP_PLAN_FAILURE';
export const DOWNGRADE_MEMBERSHIP_PLAN_REQUEST = 'DOWNGRADE_MEMBERSHIP_PLAN_REQUEST';
export const DOWNGRADE_MEMBERSHIP_PLAN_SUCCESS = 'DOWNGRADE_MEMBERSHIP_PLAN_SUCCESS';
export const DOWNGRADE_MEMBERSHIP_PLAN_FAILURE = 'DOWNGRADE_MEMBERSHIP_PLAN_FAILURE';
export const CHANGE_INTERESTED_CATEGORY_REQUEST = 'CHANGE_INTERESTED_CATEGORY_REQUEST';
export const CHANGE_INTERESTED_CATEGORY_SUCCESS = 'CHANGE_INTERESTED_CATEGORY_SUCCESS';
export const CHANGE_INTERESTED_CATEGORY_FAILURE = 'CHANGE_INTERESTED_CATEGORY_FAILURE';
export const CHANGE_REQUEST_STATUS_REQUEST = 'CHANGE_REQUEST_STATUS_REQUEST';
export const CHANGE_REQUEST_STATUS_SUCCESS = 'CHANGE_REQUEST_STATUS_SUCCESS';
export const CHANGE_REQUEST_STATUS_FAILURE = 'CHANGE_REQUEST_STATUS_FAILURE';
// END users

//POSTS
export const SET_ALL_POSTS = 'SET_ALL_POSTS';
//GLOBAL
export const SET_ERRORS = 'SET_ERRORS';
export const SET_LOADING = 'SET_LOADING';
export const SET_STATES = 'SET_STATES';

// START carousel
export const CAROUSEL_SHOW = 'CAROUSEL_SHOW';
export const CAROUSEL_HIDE = 'CAROUSEL_HIDE';
// END carousel

// START NOTIFICATIONS
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE';
export const STATUS_CREATE_NOTIFICATION = 'STATUS_CREATE_NOTIFICATION';
export const STATUS_EDIT_NOTIFICATION = 'STATUS_EDIT_NOTIFICATION';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
// END NOTIFICATIONS
