import React from 'react';
import {withRouter} from 'react-router-dom';

import {
  TheSidebar,
  TheFooter,
  TheHeader
} from './components'
import {CContainer} from '@coreui/react';
import {getLocalStorageWithExpiry} from '../../helper/storage';

const TheLayout = ({children}) => {
  if (!getLocalStorageWithExpiry('token')) {
    window.location.href= '/login';
    return null;
  } else {
    return (
      <div className="c-app c-default-layout">
        <TheSidebar/>
        <div className="c-wrapper">
          <TheHeader/>
          <div className="c-body">
            <main className="c-main">
              <CContainer fluid>
                {children}
              </CContainer>
            </main>
          </div>
          <TheFooter/>
        </div>
      </div>
    );
  }
}


export default withRouter(TheLayout);
