import React from 'react';

const fullScreenSpinnerStyle = {
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.16)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: 999999
};

export const FullScreenSpinner = () => (
  <div style={fullScreenSpinnerStyle}>
    <div className="spinner-border text-info" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export const Spinner = () => (
  <div className="spinner-border text-info" role="status">
    <span className="sr-only">Loading...</span>
  </div>
);
