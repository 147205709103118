import ApiCaller from '../helper/apiCaller';
import ConvertJsonToQueryString from '../helper/convertJsonToQueryString';

export const getByFilterService = filters => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user?${ConvertJsonToQueryString(filters)}`,
	});
};

export const getByIdService = id => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/${id}`,
	});
};

export const getUserPWService = id => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/${id}/onetimepassword`,
	});
};

export const uploadImageService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'multipart/form-data' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/upload`,
		data,
	});
};
export const isUserTestService = (data, userId) => {
	return ApiCaller({
		method: 'patch',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/${userId}`,
		data,
	});
};
export const deleteUsersService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/delete-test-user`,
		data,
	});
};

export const upgradeMembershipPlanService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/upgrade_membership_plan`,
		data,
	});
};
export const downgradeMembershipPlanService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/downgrade_membership_plan`,
		data,
	});
};
export const getUserUpgradeDownGradeListService = filters => {
	return ApiCaller({
		method: 'get',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/upgrade_downgrade/list?${ConvertJsonToQueryString(
			filters,
		)}`,
	});
};
export const changeInterestedCategoryService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/change_interest_cat`,
		data,
	});
};
export const changeRequestStatusService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/user/change_request_status`,
		data,
	});
};
