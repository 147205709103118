import ApiCaller from '../helper/apiCaller';
import ConvertJsonToQueryString from '../helper/convertJsonToQueryString';

export const getByFilterService = filters => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/filter?${ConvertJsonToQueryString(filters)}`,
	});
};
export const createCompanyService = data => {
	return ApiCaller({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/create`,
		data,
	});
};
export const getInformationService = () => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/information`,
	});
};
export const findService = id => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/find/${id}`,
	});
};
export const updateStatusService = data => {
	return ApiCaller({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/update-status`,
		data,
	});
};
export const uploadImageService = data => {
	return ApiCaller({
		method: 'post',
		headers: { 'Content-Type': 'multipart/form-data' },
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/upload`,
		data,
	});
};
export const deletePhotoDocumentsService = (companyId, documentId) => {
	return ApiCaller({
		method: 'delete',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/delete/photo_documents/${companyId}/${documentId}`,
	});
};
export const deletePhotoCertificateService = (companyId, certificateId) => {
	return ApiCaller({
		method: 'delete',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/delete/photo_certificate/${companyId}/${certificateId}`,
	});
};
export const deletePhotoCompanyService = (companyId, companyPhotoId) => {
	return ApiCaller({
		method: 'delete',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/company/delete/photo_company/${companyId}/${companyPhotoId}`,
	});
};
