import React from 'react';
import TheLayout from './layouts/dashboard/TheLayout';
import PublicLayout from './layouts/public';

const SigIn = React.lazy(() => import('./views/Auth/SignIn/SignInComponent'));
const Logout = React.lazy(() => import('./views/Auth/Logout/UserLogoutCom'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const CompaniesListAll = React.lazy(() => import('./views/Companies/ListAll/CompaniesList'));
const CreateCompany = React.lazy(() => import('./views/Companies/Create/CreateCompany'));
const CompanyDetail = React.lazy(() => import('./views/Companies/detail'));
const Posts = React.lazy(() => import('./views/Posts/ListAll/PostsList'));
const PostsDetail = React.lazy(() => import('./views/Posts/detail'));
const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));
const UsersListAll = React.lazy(() => import('./views/Users/listAll'));
const ChangeLevel = React.lazy(() => import('./views/Users/changeLevel'));
const UserDetail = React.lazy(() => import('./views/Users/detail'));
const PublicNotifications = React.lazy(() => import('./views/Notifications/public'));
const ReadyNotifications = React.lazy(() => import('./views/Notifications/ready'));

const routes = [
	{
		path: '/',
		exact: true,
    name: 'Home',
		layout: TheLayout,
		component: Dashboard,
	},
	{
		path: '/companies/list-all',
		name: 'List All',
		layout: TheLayout,
		component: CompaniesListAll,
	},
	{
		path: '/companies/create',
		name: 'Create Company',
		layout: TheLayout,
		component: CreateCompany,
	},
	{
		path: '/companies/detail/:id',
		name: 'Detail Company',
		layout: TheLayout,
		component: CompanyDetail,
	},
	{
		path: '/opportunities',
		name: 'Opportunities',
		layout: TheLayout,
		exact: true,
		component: Posts,
	},
	{
		path: '/opportunities/list-all',
		name: 'List All',
		layout: TheLayout,
		component: Posts,
	},
	{
		path: '/opportunities/detail/:id',
		name: 'List All',
		layout: TheLayout,
		component: PostsDetail,
	},
	{
		path: '/users',
		exact: true,
		name: 'Users',
		layout: TheLayout,
		component: Users,
	},
	{
		path: '/users/detail/:id',
		exact: true,
		name: 'User Details',
		layout: TheLayout,
		component: UserDetail,
	},
	{
		path: '/Users/list-all',
		exact: true,
		name: 'List All',
		layout: TheLayout,
		component: UsersListAll,
	},
	{
		path: '/users/change-level',
		exact: true,
		name: 'Change Level',
		layout: TheLayout,
		component: ChangeLevel,
	},
	{
		path: '/login',
		exact: true,
		name: 'Login',
		layout: PublicLayout,
		component: SigIn,
	},
	{
		path: '/logout',
		exact: true,
		name: 'Logout',
		layout: PublicLayout,
		component: Logout,
	},
	{
		path: '/404',
		exact: true,
		name: 'NotFound',
		layout: PublicLayout,
		component: Page404,
	},
	{
		path: '/500',
		exact: true,
		name: 'ServerError',
		layout: PublicLayout,
		component: Page500,
	},
	{
		path: '/notifications/public',
		exact: true,
		name: 'Public-Notifications',
		layout: TheLayout,
		component: PublicNotifications,
	},
	{
		path: '/notifications/ready',
		exact: true,
		name: 'Ready-Notifications',
		layout: TheLayout,
		component: ReadyNotifications,
	},
];

export default routes;
