import Axios from 'axios';
import { History } from './history';
import { toast } from 'react-toastify';

const ApiCaller = apiCallerConfig => {
	Axios.interceptors.request.use(
		async config => {
			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);
	Axios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (!error.response) {
				toast.error('Internet access error');
				return false;
			}
			if (error.response.status >= 500) {
				toast.error('Server internal error');
				return false;
			}
			if (error.response.status === 401 && window.location.pathname !== '/login') {
				History.push('/login');
			}
			return error.response;
		},
	);
	return Axios.request(apiCallerConfig);
};

export default ApiCaller;
