import * as actionTypes from '../../constants/actionTypes';
import {
	createCompanyService,
	getByFilterService,
	getInformationService,
	findService,
	updateStatusService,
	uploadImageService,
	deletePhotoCertificateService,
	deletePhotoCompanyService,
	deletePhotoDocumentsService,
} from '../../services/companiesService';
import { getLocalStorageWithExpiry } from '../../helper/storage';
import { toast } from 'react-toastify';

export const getByFilter = filters => {
	return async dispatch => {
		dispatch({ type: actionTypes.GET_BY_FILTER_REQUEST });
		try {
			const response = await getByFilterService(filters);
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.GET_BY_FILTER_SUCCESS,
					payLoad: data.result,
				});
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.GET_BY_FILTER_FAILURE,
					error: { messages: data.messages },
				});
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.GET_BY_FILTER_FAILURE,
					error: { message: error.data.messages },
				});
			}
		}
	};
};
export const create = postData => {
	const token = getLocalStorageWithExpiry('token');
	return async dispatch => {
		dispatch({ type: actionTypes.CREATE_REQUEST });
		try {
			const response = await createCompanyService({ ...postData, token });
			const data = response.data;
			if (data.status === 200) {
				toast.success('The company has been created successfully.');
				dispatch({
					type: actionTypes.CREATE_SUCCESS,
					payLoad: data.result,
				});
			}
			if (response.status !== 200) {
				toast.warning(data.messages.toString());
				dispatch({
					type: actionTypes.CREATE_FAILURE,
					error: { messages: data.messages },
				});
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.CREATE_FAILURE,
					error: { message: error.data.messages },
				});
			}
		}
	};
};
export const getInformation = () => {
	return async dispatch => {
		dispatch({ type: actionTypes.GET_COMPANY_INFORMATION_REQUEST });
		try {
			const response = await getInformationService();
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.GET_COMPANY_INFORMATION_SUCCESS,
					payLoad: data.result.count,
				});
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.GET_COMPANY_INFORMATION_FAILURE,
					error: { messages: data.messages },
				});
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.GET_COMPANY_INFORMATION_FAILURE,
					error: { message: error.data.messages },
				});
			}
		}
	};
};
export const find = id => {
	return async dispatch => {
		dispatch({ type: actionTypes.FIND_COMPANY_REQUEST });
		try {
			const response = await findService(id);
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.FIND_COMPANY_SUCCESS,
					payLoad: data.result,
				});
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.FIND_COMPANY_FAILURE,
					error: { messages: data.messages },
				});
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.FIND_COMPANY_FAILURE,
					error: { message: error.data.messages },
				});
			}
		}
	};
};
export const updateStatus = postData => {
	const token = getLocalStorageWithExpiry('token');
	return async dispatch => {
		dispatch({ type: actionTypes.COMPANY_UPDATE_STATUS_REQUEST });
		try {
			const response = await updateStatusService({ ...postData, token });
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.COMPANY_UPDATE_STATUS_SUCCESS,
					payLoad: data.result,
				});
				await dispatch(find(postData.id));
				toast.success('Company status updated successfully.');
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.COMPANY_UPDATE_STATUS_FAILURE,
					error: { messages: data.messages },
				});
				toast.warning('Company status update failed!');
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.COMPANY_UPDATE_STATUS_FAILURE,
					error: { message: error.data.messages },
				});
				toast.warning('Company status update failed!');
			}
		}
	};
};
export const uploadImage = (postData, companyId) => {
	return async dispatch => {
		dispatch({ type: actionTypes.COMPANY_UPLOAD_IMAGE_REQUEST });
		try {
			const response = await uploadImageService(postData);
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.COMPANY_UPLOAD_IMAGE_SUCCESS,
					payLoad: data.result,
				});
				await dispatch(find(companyId));
				toast.success('Company image updated successfully.');
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.COMPANY_UPLOAD_IMAGE_FAILURE,
					error: { messages: data.messages },
				});
				toast.warning('Company image update failed!');
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.COMPANY_UPLOAD_IMAGE_FAILURE,
					error: { message: error.data.messages },
				});
				toast.warning('Company image update failed!');
			}
		}
	};
};
export const deletePhotoDocuments = (companyId, documentId) => {
	return async dispatch => {
		dispatch({ type: actionTypes.DELETE_PHOTO_DOCUMENTS_REQUEST });
		try {
			const response = await deletePhotoDocumentsService(companyId, documentId);
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_DOCUMENTS_SUCCESS,
					payLoad: data.result,
				});
				await dispatch(find(companyId));
				toast.success('Document image deleted successfully.');
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_DOCUMENTS_FAILURE,
					error: { messages: data.messages },
				});
				toast.warning('Document image deletion failed!');
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_DOCUMENTS_FAILURE,
					error: { message: error.data.messages },
				});
				toast.warning('Document image deletion failed!');
			}
		}
	};
};
export const deletePhotoCertificate = (companyId, certificateId) => {
	return async dispatch => {
		dispatch({ type: actionTypes.DELETE_PHOTO_CERTIFICATE_REQUEST });
		try {
			const response = await deletePhotoCertificateService(companyId, certificateId);
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_CERTIFICATE_SUCCESS,
					payLoad: data.result,
				});
				await dispatch(find(companyId));
				toast.success('Certification image deleted successfully.');
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_CERTIFICATE_FAILURE,
					error: { messages: data.messages },
				});
				toast.warning('Certification image deletion failed!');
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_CERTIFICATE_FAILURE,
					error: { message: error.data.messages },
				});
				toast.warning('Certification image deletion failed!');
			}
		}
	};
};
export const deletePhotoCompany = (companyId, companyPhotoId) => {
	return async dispatch => {
		dispatch({ type: actionTypes.DELETE_PHOTO_COMPANY_REQUEST });
		try {
			const response = await deletePhotoCompanyService(companyId, companyPhotoId);
			const data = response.data;
			if (data.status === 200) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_COMPANY_SUCCESS,
					payLoad: data.result,
				});
				await dispatch(find(companyId));
				toast.success('Company image deleted successfully.');
			}
			if (response.status !== 200) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_COMPANY_FAILURE,
					error: { messages: data.messages },
				});
				toast.warning('Company image deletion failed!');
			}
		} catch (error) {
			if (error.data) {
				dispatch({
					type: actionTypes.DELETE_PHOTO_COMPANY_FAILURE,
					error: { message: error.data.messages },
				});
				toast.warning('Company image deletion failed!');
			}
		}
	};
};
