import styled from 'styled-components';


export const CarouselFullContainer = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .29);
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`;
export const Close = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const Img = styled.img`
  display: block;
  width:800px;
  //height:400px;
  max-height:90vh;
  margin: 0 auto;
`;
