import * as actionTypes from './../../constants/actionTypes';
import { loginService } from '../../services/authService';
import { setLocalStorageWithExpiry } from '../../helper/storage';
import { History } from '../../helper/history';

export const login = (postData) => {
  return async (dispatch) => {
    dispatch({type: actionTypes.LOGIN_REQUEST});
    try {
      const response =  await loginService(postData);
      const data = response.data;

      // set token time one day
      const now = new Date();
      let tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (data.status === 200) {
        dispatch({
          type: actionTypes.LOGIN_SUCCESS,
          payLoad: { token: data.result._id, name: data.result.name }
        });
        setLocalStorageWithExpiry('token', data.result._id, tomorrow);
        History.push('/');
      }
      if (response.status === 401) {
        dispatch({
          type: actionTypes.LOGIN_FAILURE,
          error: { messages: data.messages }
        });
      }
    } catch (error) {
      if (error.data) {
        dispatch({
          type: actionTypes.LOGIN_FAILURE,
          error: { message: error.data.messages }
        });
      }
    }
  };
};
