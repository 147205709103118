export default [
	{
		_tag: 'CSidebarNavItem',
		name: 'Dashboard',
		to: '/',
		icon: 'cil-speedometer',
	},
	{
		_tag: 'CSidebarNavTitle',
		_children: ['Tools'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Companies',
		route: '/companies',
		icon: 'cil-puzzle',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'List all',
				to: '/companies/list-all',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Create Company',
				to: '/companies/create',
			},
		],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Opportunities',
		route: '/opportunities',
		icon: 'cil-puzzle',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'List all',
				to: '/opportunities/list-all',
			},
		],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Users',
		route: '/users',
		icon: 'cil-user',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Create User',
				to: '/companies/create',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'List All',
				to: '/users/list-all',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Upgrade/Downgrade Requests',
				to: '/users/change-level',
			},
		],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Notifications',
		route: '/notifications',
		icon: 'cil-bell',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Ready notifications',
				to: '/notifications/ready',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Batch notifications',
				to: '/notifications/public',
			},
		],
	},
];
