import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  countries: {
    loading: false,
    data: [],
    error: ''
  },
  regions: {
    loading: false,
    data: [],
    error: ''
  },
  languages: {
    loading: false,
    data: [],
    error: ''
  },
  categories: {
    loading: false,
    data: [],
    error: ''
  },
  packages: {
    loading: false,
    data: [],
    error: ''
  },
};

const Definitions = (state = initialState, { type, payLoad, error }) => {
  switch (type) {

    case actionTypes.COUNTRIES_REQUEST:
      return {...state, countries: { ...state.countries, loading: true }};
    case actionTypes.COUNTRIES_SUCCESS:
      return {...state, countries: { ...state.countries, data: payLoad, loading: false }};
    case actionTypes.COUNTRIES_FAILURE:
      return {...state, countries: { ...state.countries, error: error, loading: false }};

    case actionTypes.REGIONS_REQUEST:
      return {...state, regions: { ...state.regions, loading: true }};
    case actionTypes.REGIONS_SUCCESS:
      return {...state, regions: { ...state.regions, data: payLoad, loading: false }};
    case actionTypes.REGIONS_FAILURE:
      return {...state, regions: { ...state.regions, error: error, loading: false }};

    case actionTypes.LANGUAGES_REQUEST:
      return {...state, languages: { ...state.languages, loading: true }};
    case actionTypes.LANGUAGES_SUCCESS:
      return {...state, languages: { ...state.languages, data: payLoad, loading: false }};
    case actionTypes.LANGUAGES_FAILURE:
      return {...state, languages: { ...state.languages, error: error, loading: false }};

    case actionTypes.CATEGORIES_REQUEST:
      return {...state, categories: { ...state.categories, loading: true }};
    case actionTypes.CATEGORIES_SUCCESS:
      return {...state, categories: { ...state.categories, data: payLoad, loading: false }};
    case actionTypes.CATEGORIES_FAILURE:
      return {...state, categories: { ...state.categories, error: error, loading: false }};

    case actionTypes.PACKAGES_REQUEST:
      return {...state, packages: { ...state.packages, loading: true }};
    case actionTypes.PACKAGES_SUCCESS:
      return {...state, packages: { ...state.packages, data: payLoad, loading: false }};
    case actionTypes.PACKAGES_FAILURE:
      return {...state, packages: { ...state.packages, error: error, loading: false }};

    default:
      return state;
  }
};

export default Definitions;
