import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  loading: null,
  errors: [],
};

const setStates = (state, action) => {
  return {
    ...state,
    loading: action.payload.loading,
    errors: action.payload.errors,
  }
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload.loading,
  }
};

const setErrors = (state, action) => {
  return {
    ...state,
    errors: action.payload.errors,
  }
};


const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STATES:
      return setStates(state, action);
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_ERRORS:
      return setErrors(state, action);
    default:
      return state;
  }
};

export default globalReducer;
