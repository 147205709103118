import ApiCaller from '../helper/apiCaller';
import ConvertJsonToQueryString from '../helper/convertJsonToQueryString';

export const getByFilterService = filters => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/post?${ConvertJsonToQueryString(filters)}`, // ?token=string&page=1&take=10
	});
};
export const getByIdService = id => {
	return ApiCaller({
		method: 'get',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/post/${id}`,
	});
};
export const updateStatusService = data => {
console.log(data.status);
console.log(`${process.env.REACT_APP_API_URL}/api/v3/admin/post/update-visibility`)
	return ApiCaller({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/post/update-visibility`,
		data,
	});
};
export const deletePhotoPostService = (postId, photoId) => {
	return ApiCaller({
		method: 'delete',
		url: `${process.env.REACT_APP_API_URL}/api/v3/admin/post/delete/photo_post/${postId}/${photoId}`,
	});
};
