import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';


import reducers from './store/reducers/index';
  import { Provider } from 'react-redux';
import {
  createStore
  , applyMiddleware
  , compose
} from 'redux';
import thunk from "redux-thunk";
// import {createLogger} from 'redux-logger';

const middleware = [];
if (process.env.NODE_ENV !== 'production') {
  // middleware.push(createLogger())
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers
  // development
  , composeEnhancers(applyMiddleware(...middleware, thunk))
  // production
  // , composeEnhancers(applyMiddleware(thunk))
);
React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
