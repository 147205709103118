export const setLocalStorageWithExpiry = (key, value, ttl) => {
	const now = new Date();
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};
	setLocalStorage(key, item);
};
export const getLocalStorageWithExpiry = key => {
	const item = getLocalStorage(key);
	if (!item) {
		return null;
	}
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		clearLocalStorage(key);
		return null;
	}
	return item.value;
};

export const setLocalStorage = (key, value) => {
	if (!key) {
		throw 'key is required!';
	} else {
		localStorage.setItem(key, JSON.stringify(value));
	}
};

export const getLocalStorage = key => {
	const itemString = localStorage.getItem(key);
	if (itemString) {
		try {
			return JSON.parse(itemString);
		} catch (error) {
			console.log(error);
			localStorage.clear();
			return null;
		}
	}
	return null;
};

export const clearLocalStorage = (key = null) => {
	if (!key) {
		localStorage.clear();
	} else {
		localStorage.removeItem(key);
	}
};
